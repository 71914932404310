(function() {
    var hamburger = document.getElementById('hamburger'),
        mainNav = document.getElementById('nav'),
        detailPage = document.getElementById('work-detail'),
        detailsIcon = document.getElementById('details-icon'),
        detailsModal = document.getElementById('details-modal'),
        expandIcon = document.getElementById('expand-icon'),
        workImage = document.getElementById('work-image'),
        cursor = document.getElementById('cursor'),
        cursorContent = document.getElementById('cursor-content'),
        cursorArrow = document.getElementById('cursor-arrow'),
        cursorIndex = document.getElementById('cursor-index'),
        cursorTotal = document.getElementById('cursor-total'),
        slideshow = document.getElementById('home-slideshow'),
        workDetailCloseLines = document.querySelectorAll('.work-detail__line'),
        fullScreen = document.querySelector('.full-screen'),
        noScrollClass = 'no-scroll',
        hamburgerOpenClass = 'sa__hamburger--open',
        detailBlackClass = 'work-detail--black',
        detailsOpenClass = 'work-detail__plus--open',
        detailsClosedClass = 'work-details__details--closed',
        detailsHiddenClass = 'work-details__details--hidden',
        navClosedClass = 'sa__main-nav--closed',
        expandClosedClass = 'work-details__expand--closed',
        expandOpenClass = 'work-details__expand--open',
        expandHiddenClass = 'work-detail__expand-block--hidden',
        cursorOverrideClass = 'sa-override-cursor',
        cursorArrowRightClass = 'sa-cursor__arrow--right',
        cursorArrowLeftClass = 'sa-cursor__arrow--left',
        workDetailCloseBlackClass = 'work-detail__line--black',
        isTouchDevice = 'ontouchstart' in document.documentElement;

    setTouch();
    setNoScroll();
    setCursor();
    setHamburger();
    setDetails();
    setImage();
    setSlideshow();
    setDetailPrevNext();

    var mySwiper = new Swiper('.swiper-container', {
        simulateTouch: false,
        speed: 0
    });
    window.addEventListener('resize', handleResize);

    function setTouch() {
        if (isTouchDevice) {
            document.body.classList.add('is-touch');
        }
    }

    function preventDefaultEvent(evt) {
       evt.preventDefault();
    }

    function handleResize() {
        if (mainNav) {
            closeNav();
        }
    }

    function setNoScroll() {
        if (fullScreen) {
            document.body.classList.add(noScrollClass);
        }
    }

    function setCursor() {
        if (!isTouchDevice && cursor) {
            document.body.classList.add('sa-override-cursor');
            window.addEventListener('mousemove', handleCursor);
        }
    }

    function setHamburger() {
        if (hamburger) {
            hamburger.addEventListener('click', toggleNav);
        }
    }

    function setDetails() {
        if (detailsIcon) {
            detailsIcon.addEventListener('click', toggleDetails);
        }
    }

    function setImage() {
        if (expandIcon) {
            expandIcon.addEventListener('click', toggleImage);
        }
    }

    function setSlideshow() {
        if (!slideshow) {
            return;
        }

        document.body.addEventListener('click', function(ev) {
            var targetCursor = window.getComputedStyle(ev.target, null).getPropertyValue('cursor');
            if (targetCursor !== 'none') {
                return;
            }
            var current = parseInt(cursorIndex.innerText);
            var total = parseInt(cursorTotal.innerText);
            var isLeft = isLeftScreen(ev);

            if (isLeft && current > 1) {
                mySwiper.slidePrev();
                current--;
            } else if (!isLeft && current < total) {
                mySwiper.slideNext();
                current++;
            }

            cursorIndex.innerText = current;
        });
    }

    function setDetailPrevNext() {
        if (!detailPage) {
            return;
        }

        // TODO: abstract this callback
        document.body.addEventListener('click', function(ev) {
            var targetCursor = window.getComputedStyle(ev.target, null).getPropertyValue('cursor');
            if (targetCursor !== 'none') {
                return;
            }
            var current = parseInt(cursorIndex.innerText);
            var total = parseInt(cursorTotal.innerText);
            var isLeft = isLeftScreen(ev);

            var url = '';
            if (isLeft && current > 1) {
                url = document.getElementById('prev');
            } else if (!isLeft && current < total) {
                url = document.getElementById('next');
            }

            if (url) {
                window.location = url;
            }
        });
    }

    function toggleNav() {
        if (hamburger.classList.contains(hamburgerOpenClass)) {
            closeNav();
        } else {
            openNav();
        }
    }

    function toggleDetails() {
        if (detailsIcon.classList.contains(detailsOpenClass)) {
            closeDetails();
        } else {
            openDetails();
        }
    }

    function toggleImage() {
        if (expandIcon.classList.contains(expandOpenClass)) {
            collapseImage();
        } else {
            expandImage();
        }
    }

    function closeNav() {
        hamburger.classList.remove(hamburgerOpenClass);
        mainNav.classList.add(navClosedClass);
        document.body.removeEventListener('touchmove', preventDefaultEvent);
        setCursor();
    }

    function openNav() {
        hamburger.classList.add(hamburgerOpenClass);
        mainNav.classList.remove(navClosedClass);
        document.body.addEventListener('touchmove', preventDefaultEvent);
        removeCursor();
    }

    function closeDetails() {
        detailsIcon.classList.remove(detailsOpenClass);
        detailsModal.classList.add(detailsClosedClass);
        expandIcon.classList.remove(expandHiddenClass);
        document.body.removeEventListener('touchmove', preventDefaultEvent);
        workImage.style.display = 'inline';
        setCursor();
    }

    function openDetails() {
        detailsIcon.classList.add(detailsOpenClass);
        detailsModal.classList.remove(detailsClosedClass);
        expandIcon.classList.add(expandHiddenClass);
        document.body.addEventListener('touchmove', preventDefaultEvent);
        workImage.style.display = 'none';
        removeCursor();
    }

    function collapseImage() {
        expandIcon.classList.remove(expandOpenClass);
        workImage.classList.add(expandClosedClass);
        detailsIcon.classList.remove(detailsHiddenClass);
        document.body.classList.add(noScrollClass);
        setCursor();
        workDetailCloseLines.forEach(function(elem) {
            elem.classList.remove(workDetailCloseBlackClass);
        });
        detailPage.classList.remove(detailBlackClass);

    }

    function expandImage() {
        expandIcon.classList.add(expandOpenClass);
        workImage.classList.remove(expandClosedClass);
        detailsIcon.classList.add(detailsHiddenClass);
        document.body.classList.remove(noScrollClass);
        removeCursor();
        if (workImage.getAttribute('data-text-color') === 'black') {
            workDetailCloseLines.forEach(function(elem) {
                elem.classList.add(workDetailCloseBlackClass);
            });
            detailPage.classList.add(detailBlackClass);
        }
    }

    function removeCursor() {
        window.removeEventListener('mousemove', handleCursor);
        document.body.classList.remove(cursorOverrideClass);
    }

    function handleCursor(ev) {
        if (isTouchDevice) {
            return;
        }

        // Don't show custom cursor when hovering over any element
        // that has `cursor` explicitly set.
        if (ev.target.__proto__ !== window) {
            // Add cache if this results in poor performance
            var targetCursor = window.getComputedStyle(ev.target, null).getPropertyValue('cursor');
            if (targetCursor !== 'none') {
                cursor.style.visibility = 'hidden';
            } else {
                cursor.style.visibility = 'visible';
            }
        }

        cursor.style.left = ev.pageX - cursorContent.offsetWidth + 'px';
        cursor.style.top = ev.pageY - (cursorContent.offsetHeight / 2) + 'px';

        if (isLeftScreen(ev)) {
            cursorArrow.classList.add(cursorArrowLeftClass);
            cursorArrow.classList.remove(cursorArrowRightClass);
        } else {
            cursorArrow.classList.add(cursorArrowRightClass);
            cursorArrow.classList.remove(cursorArrowLeftClass);
        }
    }

    /**
     * Determines if a mouse event was fired on the left or right of screen
     * @param   MouseEvent mouseEvent
     * @return  Boolean                 true if on left of screen, false if on right (or center)
     */
    function isLeftScreen(mouseEvent) {
        if (mouseEvent.pageX > window.innerWidth / 2 ) {
            return false;
        }

        return true;
    }
})();
